import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { RestService } from "./rest.service";

@Injectable()
export class StatsService extends RestService {

  private _url                          = "Stats";
  private _dashboardUrl                 = "Stats/dashboard";
  private _recAnalisysUrl               = "Stats/receivablesAnalisys";
  private _companiesOverview            = "Stats/generalFinanceReport";
  private _companiesOverviewAnalytic    = "Stats/generalFinanceAnalyticReport";
  private _accessionReport              = "Stats/accessionReport";
  private _contractStatementReport      = "Stats/contractStatement";
  private _contractStatementReportPdf   = "Stats/contractStatementPdf";
  private _auditReport                  = "Stats/audit";
  private _sellReport                   = "Stats/sellReport";
  private _delayedSellsReceivables      = "Stats/delayedSellsReceivables";
  private _guestSellReport              = "Stats/guestSellReport";
  private _monthReport                  = "Stats/monthReport";
  private _financeDash                  = "Stats/financeDashboard";
  private _collegeClassPayments         = "Stats/collegeClassPayments";
  private _dre                          = "Stats/dre";
  private _balance                      = "Stats/balance";
  private _cashFlow                     = "Stats/cashFlow";
  private _rlogs                        = "Stats/receivablesLogs";
  
  constructor(protected _http: HttpClient) {
    super(_http);
  }

  getDashboard(companiesId?: number): any {
    const params = new URLSearchParams();
    if (companiesId) {
      params.set('query[months_payments_company_id]', companiesId.toString());
    }
    return this.getApi(this._dashboardUrl + '?' + params.toString());
  }

  getAccessionReports(contract_id:number){
    const params = new URLSearchParams({contract_id: contract_id.toString()});
    return this.getApi(this._accessionReport + '?' +  params.toString());
  }

  getContractsStatements(cost_center_id:number, only_gateway:any = 0, type:any = null, from:any = null, to:any = null, unit: any, action: any, order: string, account_plan_id:number=null){
    if ( only_gateway == true ) {
      only_gateway = 1;
    } else {
      only_gateway = 0;
    }
    const params = new URLSearchParams({
      cost_center_id: cost_center_id.toString(), 
      only_ebanx: only_gateway.toString(), 
      type: type,
      from: from, 
      to: to, 
      unit_id: unit, 
      action: action,
      order,
      account_plan_id: account_plan_id.toString() 
    });
    return this.getApi(this._contractStatementReport + '?' +  params.toString());
  }

  getContractsStatementsPdf(cost_center_id:number, only_gateway:any = 0, type:any = null, from:any = null, to:any = null, unit: any, action: any, order: string, account_plan_id:number=null){
    if ( only_gateway == true ) {
      only_gateway = 1;
    } else {
      only_gateway = 0;
    }
    const params = new URLSearchParams({
      cost_center_id: cost_center_id.toString(), 
      only_ebanx: only_gateway.toString(), 
      type: type,
      from: from, 
      to: to, 
      unit_id: unit, 
      action: action,
      order,
      account_plan_id: account_plan_id.toString(),
    });
    return this.getApi(this._contractStatementReportPdf + '?' +  params.toString());
  }

  getDre(cost_center_id:number, by_type:any = 'paid_date', by_model:any = 'Ym', from:any = null, to:any = null, unit: any, action: any){
    const params = new URLSearchParams({cost_center_id: cost_center_id.toString(), by_type: by_type.toString(), by_model: by_model.toString(), from: from, to: to, unit_id: unit, action: action });
    return this.getApi(this._dre + '?' +  params.toString());
  }

  getBalance(cost_center_id:number, by_type:any = 'paid_date', by_model:any = 'Ym', from:any = null, to:any = null, company: any, action: any){
    const params = new URLSearchParams({cost_center_id: cost_center_id.toString(), by_type: by_type.toString(), by_model: by_model.toString(), from: from, to: to, company_id: company, action: action });
    return this.getApi(this._balance + '?' +  params.toString());
  }

  getCashFlow(report_model: any = '',cost_center_id: number, by_type: any = 'paid_date', by_model: any = 'Ym', from: any = null, to: any = null, company: any, action: any){
    const params = new URLSearchParams({report_model:report_model,cost_center_id: cost_center_id.toString(), by_type: by_type.toString(), by_model: by_model.toString(), from: from, to: to, company_id: company, action: action });
    return this.getApi(this._cashFlow + '?' +  params.toString());
  }

  payables(from: string, to: string, cost_center_id: number[], providerId: string, accountPlanid: string, paid: string) {
    const params = new URLSearchParams({
      from: from,
      to: to,
      cost_center_id: cost_center_id.toString(),
      provider_id: providerId,
      account_plan_id: accountPlanid,
      paid: paid ? paid.toString() : null
    });

    return this.getApi(`Stats/payables?${params.toString()}`);
  }

  receivables(from: string, to: string, cost_center_id: number, receivables_status_id: any, userId: number, reference: string) {
    let params: any = {
      date_from: from,
      date_to: to
    }
    if (cost_center_id && cost_center_id != 0) params['cost_center_id'] = cost_center_id;
    if (receivables_status_id && receivables_status_id != 'all') params['receivables_status_id'] = receivables_status_id;
    if (userId && userId != 0) params['user_id'] = userId;
    if (reference) params['reference'] = reference.toUpperCase();

    params = new URLSearchParams(params);
    return this.getApi(`reports/receivables?${params.toString()}`, {}, false, true);
  }

  getAudit(filter:any){
    let f = {...filter}

    if(f.from && f.from != ''){
      f.payment_date_from = f.from.year + '-' + f.from.month + '-' + f.from.day
    }

    if(f.to && f.to != ''){
      f.payment_date_to = f.to.year + '-' + f.to.month + '-' + f.to.day
    }

    if(f.diff == true || f.diff == 'true'){
      f.only_diff = true
    }

    delete f.from
    delete f.to
    delete f.diff
    
    const params = new URLSearchParams(f);

    return this.getApi(this._auditReport + '?' +  params.toString());
  }

  getDelayedSellsReceivables(filter:any){
    let f = {...filter}

    if(f.from && f.from != ''){
      f.date_from = f.from.year + '-' + f.from.month + '-' + f.from.day
    }

    if(f.to && f.to != ''){
      f.date_to = f.to.year + '-' + f.to.month + '-' + f.to.day
    }

    if(!f.situation || f.situation == null){
      delete f.situation
    }

    delete f.from
    delete f.to

    const params = new URLSearchParams(f);
    return this.getApi(this._delayedSellsReceivables + '?' +  params.toString());
  }

  getReceivablesLogs(filter:any){
    let f = {...filter}

    if(f.from && f.from != ''){
      f.date_from = f.from.year + '-' + f.from.month + '-' + f.from.day
    }

    if(f.to && f.to != ''){
      f.date_to = f.to.year + '-' + f.to.month + '-' + f.to.day
    }

    if(!f.situation || f.situation == null){
      delete f.situation
    }

    delete f.from
    delete f.to

    const params = new URLSearchParams(f);
    return this.getApi(this._rlogs + '?' +  params.toString());
  }

  getSell(filter:any){
    let f = {...filter}

    if(f.from && f.from != ''){
      f.date_from = f.from.year + '-' + f.from.month + '-' + f.from.day
    }

    if(f.to && f.to != ''){
      f.date_to = f.to.year + '-' + f.to.month + '-' + f.to.day
    }

    if(!f.situation || f.situation == null){
      delete f.situation
    }

    delete f.from
    delete f.to

    const params = new URLSearchParams(f);
    return this.getApi(this._sellReport + '?' +  params.toString());
  }

  getGuestSell(filter:any){
    let f = {...filter}

    if(f.from && f.from != ''){
      f.date_from = f.from.year + '-' + f.from.month + '-' + f.from.day
    }

    if(f.to && f.to != ''){
      f.date_to = f.to.year + '-' + f.to.month + '-' + f.to.day
    }

    if(!f.situation || f.situation == null){
      delete f.situation
    }

    if(!f.guest_name){
      delete f.guest_name
    }
    if(!f.student_name){
      delete f.student_name
    }

    delete f.from
    delete f.to

    const params = new URLSearchParams(f);
    
    return this.getApi(this._guestSellReport + '?' +  params.toString());
  }

  getReceivablesAnalisys(filter: any): any {
    let f = {...filter}

    if(f.from && f.from != null){
      f.from = f.from.year + '-' + f.from.month + '-' + f.from.day
    }

    if(f.to && f.to != null){
      f.to = f.to.year + '-' + f.to.month + '-' + f.to.day
    }
    const params = new URLSearchParams(f);

    return this.getApi(this._recAnalisysUrl + '?' +  params.toString());
  }

  getCompaniesOverview(filter: any): any {
    let f = {...filter}

    if(f.from && f.from != null){
      f.from = f.from.year + '-' + f.from.month + '-' + f.from.day
    }

    // f.from = '2022-07-02'

    if(f.to && f.to != null){
      f.to = f.to.year + '-' + f.to.month + '-' + f.to.day
    }
    // f.to = '2022-08-31'
    const params = new URLSearchParams(f);

    return this.getApi(this._companiesOverview + '?' +  params.toString());
  }

  getCompaniesOverviewAnalytic(filter: any): any {
    let f = {...filter}
    const params = new URLSearchParams(f);

    return this.getApi(this._companiesOverviewAnalytic + '?' +  params.toString());
  }

  generateMonthReport(info: any): any {
    let data = JSON.stringify(info);
    return this.postApi(this._monthReport, data);
  }

  getFinanceDash(contracts_ids: any = []): any {
    const params = new URLSearchParams({contract_id: contracts_ids.join(',').toString()});
    return this.getApi(this._financeDash + '?' +  params.toString());
  }
  
  getCollegeClassPayments(cost_center_id: any = []): any {
    const params = new URLSearchParams({ cost_center_id: cost_center_id });
    return this.getApi(this._collegeClassPayments + '?' +  params.toString());
  }

  async getMacroIndicators(){
    return this.getApi(`${this._url}/macroIndicators`);
  }

  async adhesions(companiesIds: Array<number>) {
    const params = new URLSearchParams({companies_id: companiesIds.join(',')});

    return this.getApi(`${this._url}/adhesions?${params.toString()}`);
  }

}