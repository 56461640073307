import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestService } from './rest.service';
import { Md5 } from 'md5-typescript';

@Injectable({
  providedIn: 'root',
})
export class StoresSalesService extends RestService {
  private _url = 'StoresSales';
  private _urlCancel = 'StoresSales/cancelSale';
  private _urlGenerateAll = 'StoresSales/generateAllBills';
  private _urlAddPayments = 'StoresSales/addSaleWithPayments';

  constructor(protected _http: HttpClient) {
    super(_http);
  }

  insertStoresSales(info: any): any {
    let data = JSON.stringify(info);
    return this.postApi(this._url, data);
  }

  cancelSale(sale_id: number): any {
    let data = JSON.stringify({ sale_id: sale_id });
    return this.postApi(this._urlCancel, data);
  }

  updateStoresSales(info: any): any {
    let data = JSON.stringify(info);
    return this.putApi(this._url + '/' + info.id, data);
  }

  deleteStoresSales(id: number): any {
    return this.deleteApi(this._url + '/' + id);
  }

  updateField(id: number, field: any, value: any): any {
    let data = JSON.stringify({ id: id, field: field, value: value });
    return this.patchApi(this._url + '/' + id, data);
  }

  getStoresSales(
    filter_data: any,
    contain_string: string = '',
    order_string: string = '+StoresSales.id',
    page: number = 0,
    fields: string = '',
    limit: number = 20000
  ): any {
    let qs: string = '';
    let paging: string = '';

    if (filter_data.length > 0) {
      filter_data.map((item) => {
        if (item.operator == null || item.operator == '') {
          qs += item.field + '|' + item.value + ';';
        } else {
          qs += item.field + '|' + item.operator + '|' + item.value + ';';
        }
      });
    }

    let offset = page * limit;
    paging = offset + ';' + limit;
    return this.getApi(this._url, {
      params: {
        query: qs,
        paging: paging,
        order: order_string,
        contains: contain_string,
        fields: fields,
      },
    });
  }

  getStoreSale(id, contain_string: string = '') {
    return this.getApi(this._url + '/' + id, {
      params: { contains: contain_string },
    });
  }

  async createSaleFromBriefing(briefingId: number) {
    return await this.postApi(
      this._url + '/createSaleFromBriefing',
      JSON.stringify({ briefing_id: briefingId })
    );
  }

  async generateAllBills(id: any) {
    return await this.postApi(
      this._urlGenerateAll,
      JSON.stringify({ id: Md5.init(id) })
    );
  }
  async createSaleWithPayments(saleInfo: any, payments: any[]): Promise<any> {
    const data = JSON.stringify({ saleInfo, payments });
    return this.postApi(`${this._urlAddPayments}`, data);
  }

  addPaymentsToBriefing(briefingId: number, payments: any[]): Promise<any> {
    const data = { payments };
    return this.postApi(
      `${this._url}/addPaymentsToExistingBriefing/${briefingId}`,
      data
    );
  }
}
